<template>
  <v-autocomplete
    :label="label"
    :value="current"
    :items="candidate"
    :loading="loading"
    :filter="inputFilter"
    item-value="supplierCd"
    item-text="supplierName"
    :disabled="fieldDisabled"
    :rules="rules"
    @change="onSelected"
    clearable
    hide-no-data
    filled
    :attach="attach"
    dense
  ></v-autocomplete>
</template>

<script>
export default {
  name: "SupplierField",
  model: { prop: "code", event: "selected" },
  props: {
    label: { type: String, default: "仕入先名" },
    code: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    corporate: { type: String, default: null },
    disabledNoData: { type: Boolean, default: false },
    attach: { type: Boolean, default: true },
    rules: { type: Array, default: () => [] },
  },
  data() {
    return {
      loading: null,
      candidate: [],
      current: null,
      inputFilter: (item, queryText) => item.supplierCd.includes(queryText) || item.supplierName.includes(queryText),
    };
  },
  async mounted() {
    await this.initSuppliers(this.corporate, this.code);
  },
  watch: {
    async corporate(corporationCd) {
      await this.initSuppliers(corporationCd, this.code);
    },
    async code(value) {
      if (value) {
        if (this.current == null || this.current.supplierCd !== value) {
          await this.initSuppliers(this.corporate, value);
        }
      } else {
        this.current = null;
      }
    },
  },
  computed: {
    fieldDisabled() {
      return this.disabled || (this.disabledNoData && this.candidate.length === 0);
    },
  },
  methods: {
    onSelected(value) {
      this.current = this.candidate.find((c) => c.supplierCd === value);
      this.$emit("selected", value);
    },
    selected() {
      return this.current;
    },
    async initSuppliers(corporationCd, supplierCd) {
      try {
        if (corporationCd && corporationCd.length > 0) {
          this.loading = true;
          const params = { corporationCd };
          const response = await this.$store.dispatch("supplier/suggest", params);
          this.candidate = response.data.contents.suppliers;
        } else {
          this.candidate = [];
        }
        if (this.candidate.length === 0) {
          this.current = null;
          this.$emit("selected", null);
        } else {
          this.current = this.candidate.find((c) => c.supplierCd === supplierCd);
          this.$emit("selected", this.current ? this.current.supplierCd : null);
        }
      } catch (error) {
        console.error("SupplierField::initSuppliers", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
