<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-app-bar dense>
          <v-app-bar-title><v-icon>mdi-account-search</v-icon>ユーザ一覧</v-app-bar-title>
          <v-spacer></v-spacer>
          <error-grid-dialog ref="errorGrid" width="1100px" height="350px"></error-grid-dialog>
          <v-form ref="searchForm" lazy-validation>
            <search-conditions @search="onSearchClick">
              <v-text-field
                v-model="searchModel.corporationName"
                label="法人名"
                dense
                v-if="isCafereoUser"
              ></v-text-field>
              <v-text-field v-model="searchModel.groupName" label="取引先名" dense v-if="isVendorUser"></v-text-field>
              <v-text-field v-model="searchModel.userName" label="ユーザ名" dense v-if="isMakerUser"></v-text-field>
            </search-conditions>
          </v-form>
          <tooltip-icon-button icon="mdi-refresh" @click="onSearchClick">リフレッシュ</tooltip-icon-button>
          <v-divider vertical></v-divider>
          <template v-if="isCreateActions()">
            <tooltip-icon-button icon="mdi-account-plus" @click="onCreateClick">ユーザ追加</tooltip-icon-button>
            <v-divider vertical></v-divider>
          </template>
          <template>
            <tooltip-icon-button
              v-if="isUpdateActions()"
              icon="mdi-account-edit"
              @click="onEditClick"
              :disabled="!selectedRow"
              >ユーザ編集</tooltip-icon-button
            >
            <tooltip-icon-button
              v-if="isDeleteActions()"
              icon="mdi-account-remove"
              @click="onRemoveClick"
              :disabled="selectionRows.length === 0"
              >ユーザ削除</tooltip-icon-button
            >
            <tooltip-icon-button v-if="isCsvActions()" icon="mdi-download" @click="onExportClick"
              >CSVダウンロード</tooltip-icon-button
            >
            <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
            <v-dialog v-model="editDialog" max-width="800px" persistent scrollable>
              <user-entry
                :inputModel="userModel"
                v-if="editDialog"
                :entryCorporationType="entryCorporationType"
                :entryCorporationRoot="entryCorporationRoot"
                @cancel="editDialog = false"
                @created="onCreated"
                @updated="updated"
              ></user-entry>
            </v-dialog>
            <v-divider vertical></v-divider>
          </template>
          <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="detailSelected"
            >詳細表示</tooltip-icon-toggle-button
          >
        </v-app-bar>
      </v-col>
    </v-row>
    <v-row style="height: 100%">
      <v-col
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 9 : 12"
        v-show="!detailSelected || !infoMaximum"
      >
        <ag-grid-vue
          id="UserList"
          class="ag-theme-alpine"
          style="height: 98%"
          :defaultColDef="gridOptions.defaultColDef"
          :columnDefs="gridOptions.columnDefs"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="gridOptions.paginationPageSize"
          :suppressCellSelection="true"
          :enableCellTextSelection="true"
          :suppressCsvExport="false"
          :suppressExcelExport="true"
          :defaultCsvExportParams="defaultCsvExportParams"
          :localeText="gridOptions.localeText"
          :columnTypes="columnTypes"
          :frameworkComponents="frameworkComponents"
          rowSelection="multiple"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          :getRowNodeId="(data) => data.userId"
        >
        </ag-grid-vue>
      </v-col>
      <v-col v-if="detailSelected" id="UserInfos" style="flex-basis: auto; display: flex" :cols="infoMaximum ? 12 : 3">
        <v-divider vertical></v-divider>
        <user-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :userId="selectedRow != null ? selectedRow.userId : null"
        ></user-infos>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { getDisplayDetails, typeFormat } from "../../models/ag-grid/helpers";
import UserInfos from "../../components/user/UserInfos.vue";
import CorporationTypes from "../../consts/CorporationTypes";
import { CafereoPrivileges, MakerPrivileges, VendorPrivileges } from "../../consts/UserPrivileges";
import UserPrivileges from "../../consts/UserPrivileges";
import { statuses as ApiStatus } from "../../libs/api-client";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import UserEntry from "../../components/user/UserEntry.vue";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import { NumericColumn, FullDateColumn } from "../../models/ag-grid/columnTypes";

export default {
  name: "UserSearch",
  components: {
    AgGridVue,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    UserEntry,
    ErrorGridDialog,
    UserInfos,
  },
  data: () => ({
    constants: {
      createActions: ["C060102", "C060202", "C060302", "V030102", "M030102"],
      updateActions: ["C060103", "C060203", "C060303", "V030103", "M030103"],
      deleteActions: ["C060104", "C060204", "C060304", "V030104", "M030104"],
      exportActions: ["C060105", "C060205", "C060305", "V030105", "M030105"],
      cafereoActions: [
        "C060102",
        "C060202",
        "C060302",
        "C060103",
        "C060203",
        "C060303",
        "C060104",
        "C060204",
        "C060304",
        "C060105",
        "C060205",
        "C060305",
      ],
      makerActions: ["M030102", "M030103", "M030104", "M030105"],
      vendorActions: ["V030102", "V030103", "V030104", "V030105"],
    },
    gridStyle: { height: "95%" },
    detailSelected: false,
    shownInfo: false,
    infoMaximum: false,
    activeTab: null,
    searchModel: {
      corporationName: "",
      groupName: "",
      userName: "",
    },
    gridOptions: {
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: "agTextColumnFilter",
        suppressSizeToFit: true,
        filterParams: {
          newRowsAction: "keep",
        },
      },
      columnDefs: [
        {
          colId: "selection",
          width: 50,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          sortable: false,
          filter: false,
        },
      ],
      getRowNodeId: (data) => data.userId,
      rowSelection: "multiple",
      suppressCellSelection: true,
      pagination: true,
      paginationPageSize: null,
      enableCellTextSelection: true,
      localeText: AG_GRID_LOCALE_JA,
    },
    defaultCsvExportParams: {
      allColumns: true,
      onlySelectedAllPages: false,
    },
    columnTypes: {
      dpNumericColumn: NumericColumn,
      dpFullDateColumn: FullDateColumn,
    },
    frameworkComponents: {
      dpBooleanFilter: BooleanFilter,
      dpSelectionFilter: SelectionFilter,
    },
    rowData: [],
    userRecords: [],
    selectionRows: [],
    editDialog: false,
    userModel: {
      createNew: null,
      userRecord: null,
    },
    availablePrivileges: null,
  }),
  watch: {
    globalPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
      this.handleResize();
    },
    gridHeightSize(value) {
      this.gridStyle.height = value + "px";
    },
  },
  beforeMount() {
    this.initPrivileges();

    this.gridOptions.paginationPageSize = this.globalPageSize;
    this.gridOptions.columnDefs.push({
      field: "userId",
      headerName: "ユーザID",
      width: 130,
    });
    if (this.isCafereoUser && (this.isMakerList || this.isVendorList)) {
      this.gridOptions.columnDefs.push({
        field: "corporationName",
        headerName: "法人名",
        width: 300,
      });
    }
    let groupNameTitle;
    if (this.isMakerUser) {
      groupNameTitle = "会社名";
    } else if (this.isVendorUser) {
      groupNameTitle = "取引先名";
    } else {
      if (this.isCafereoList) {
        groupNameTitle = "所属";
      } else {
        groupNameTitle = "事業所名";
      }
    }

    if (this.isMakerUser || this.isVendorUser) {
      this.gridOptions.columnDefs.push(
        { field: "userName", headerName: "氏名", width: 250 },
        {
          field: "groupName",
          headerName: groupNameTitle,
          width: 300,
        }
      );
    } else {
      this.gridOptions.columnDefs.push(
        {
          field: "groupName",
          headerName: groupNameTitle,
          width: 300,
        },
        { field: "userName", headerName: "氏名", width: 250 }
      );
    }
    this.gridOptions.columnDefs.push(
      {
        colId: "privilege",
        headerName: "権限",
        width: 120,
        filter: "dpSelectionFilter",
        filterParams: { options: this.availablePrivileges },
        valueGetter: (params) => UserPrivileges.of(params.data.privilege),
      },
      {
        colId: "status",
        headerName: "ステータス",
        width: 120,
        valueGetter: (params) => (params.data.inActivated ? "無効" : params.data.locked ? "ロック中" : "有効"),
      }
    );
  },
  async mounted() {
    this.onSearchClick();
    this.handleResize();
    // 画面解像度による画面サイズ取得
    this.gridStyle.height = this.gridHeightSize + "px";
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    isCafereoList() {
      return this.$route.name === "UserSearch";
    },
    isMakerList() {
      return this.$route.name === "MakerUserSearch";
    },
    isVendorList() {
      return this.$route.name === "VendorUserSearch";
    },
    entryCorporationType() {
      if (this.isCafereoList && this.allowedAction(this.constants.cafereoActions)) {
        return CorporationTypes.CAFEREO;
      } else if (this.isMakerList || this.allowedAction(this.constants.makerActions)) {
        return CorporationTypes.MAKER;
      } else if (this.isVendorList || this.allowedAction(this.constants.vendorActions)) {
        return CorporationTypes.VENDOR;
      }
      return null;
    },
    entryCorporationRoot() {
      if (this.allowedAction(this.constants.cafereoActions)) {
        return CorporationTypes.CAFEREO;
      } else if (this.allowedAction(this.constants.makerActions)) {
        return CorporationTypes.MAKER;
      } else if (this.allowedAction(this.constants.vendorActions)) {
        return CorporationTypes.VENDOR;
      }
      return null;
    },
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      return getDisplayDetails(this.selectedRow.userId, this.gridOptions.api, this.gridOptions.columnApi);
    },
    vendorDisabledRule() {
      return (
        this.isVendorUser &&
        this.selectionRows.some((r) => r.groupCd !== this.$store.getters["security/loggedInUser"].groupCode)
      );
    },
  },
  methods: {
    initPrivileges() {
      if (this.isMakerUser || this.isMakerList) {
        this.availablePrivileges = MakerPrivileges.all();
      } else if (this.isVendorUser || this.isVendorList) {
        this.availablePrivileges = VendorPrivileges.all();
      } else {
        this.availablePrivileges = [{ value: 0, text: "-" }, ...CafereoPrivileges.all()];
      }
    },
    clearFilters() {
      this.gridOptions.api.setFilterModel(null);
      this.gridOptions.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    onGridReady(params) {
      this.gridOptions.api = params.api;
      this.gridOptions.columnApi = params.columnApi;
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionRows = [];
        let corporationType;
        if (this.isCafereoUser && this.isCafereoList) {
          corporationType = CorporationTypes.CAFEREO;
        } else if (this.isMakerUser || this.isMakerList) {
          corporationType = CorporationTypes.MAKER;
        } else if (this.isVendorUser || this.isVendorList) {
          corporationType = CorporationTypes.VENDOR;
        }
        const params = { corporationType, ...this.searchModel };
        const response = await this.$store.dispatch("user/search", params);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        const result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: "ユーザ一覧",
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.users).length === 0) {
          this.$dialog.warning({
            title: "ユーザ一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridOptions.api.setRowData(result.users);
      } catch (error) {
        console.error("UserSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onCreateClick() {
      this.userModel = { createNew: true, userRecord: null };
      this.editDialog = true;
    },
    onEditClick() {
      this.userModel = { createNew: false, userRecord: this.selectedRow };
      this.editDialog = true;
    },
    onCreated() {
      this.editDialog = false;
      this.onSearchClick();
    },
    onUpdated(userRecord) {
      // Note: グリッドのキー(getRowNodeId)がユーザIDのためそのまま反映が可能
      this.editDialog = false;
      this.gridOptions.api.applyTransaction({ update: [userRecord] });
    },
    async onRemoveClick() {
      try {
        const messageText = `選択されたユーザを削除します <small>(${this.selectionRows.length}件)</small>`;
        const ok = await this.$dialog.confirm({ title: "ユーザ一覧", text: messageText });
        if (ok) {
          this.loadingOn();
          const users = {
            users: this.selectionRows.map((r) => ({
              userId: r.userId,
              updateDate: r.updateDate,
            })),
          };
          const response = await this.$store.dispatch("user/remove", users);
          let errorMsg = {};
          let tmpErrorMsg = {};
          switch (response.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$dialog.notify.info(`ユーザが削除されました (${this.selectionRows.length}件)`);
              this.selectionRows = [];
              this.onSearchClick();
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
              tmpErrorMsg = this.selectionRows.map(function (r) {
                let tmp = null;
                if (response.data.header.messages[r.userId]) {
                  tmp = {
                    userId: r.userId,
                    errorMsg: response.data.header.messages[r.userId]?.join(),
                  };
                }
                return tmp;
              });
              errorMsg = tmpErrorMsg.filter((r) => r != null);

              this.$refs.errorGrid.open({
                title: "エラーメッセージ",
                columns: [
                  {
                    headerName: "ユーザID",
                    field: "userId",
                    pinned: "left",
                  },
                  { headerName: "エラーメッセージ", field: "errorMsg" },
                ],
                records: errorMsg,
                onClose: async () => {
                  this.selectionRows = [];
                  this.onSearchClick();
                },
              });
              break;
            default:
              this.redirectError();
              break;
          }
        }
      } catch (error) {
        console.error("UserSearch::onRemoveClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onExportClick() {
      var allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.gridOptions.api.exportDataAsCsv({
        allColumns: true,
        onlySelected: this.selectionRows.length > 0,
        fileName: `ユーザ一覧`,
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      this.selectionRows = this.gridOptions.api.getSelectedRows();
    },
    async updated(user) {
      try {
        this.selectedRow.userId = user.userId;
        this.selectedRow.userName = user.userName;
        this.selectedRow.corporationCd = user.corporationCd;
        this.selectedRow.groupCd = user.groupCd;
        this.selectedRow.groupName = user.groupName;
        this.selectedRow.phoneNo = user.phoneNo;
        this.selectedRow.mailAddress = user.mailAddress;
        this.selectedRow.privilege = user.privilege;
        this.selectedRow.locked = user.locked;
        this.selectedRow.inActivated = user.inActivated;
        this.selectedRow.password = null;
        this.selectedRow.password2 = null;
        this.selectedRow.updateDate = user.updateDate;

        this.gridOptions.api.applyTransaction({ update: this.selectionRows });
        this.editDialog = false;
        this.$dialog.notify.info(`ユーザ情報を更新しました (${user.userId})`, { timeout: 2300 });
      } catch (error) {
        console.error("UserSearch::update", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    isCreateActions() {
      switch (this.entryCorporationRoot) {
        case CorporationTypes.CAFEREO:
          if (this.isCafereoList) {
            return this.allowedAction(["C060102"]);
          } else if (this.isMakerList) {
            return this.allowedAction(["C060202"]);
          } else if (this.isVendorList) {
            return this.allowedAction(["C060302"]);
          } else {
            return false;
          }
        case CorporationTypes.MAKER:
          return this.allowedAction(["M030102"]);
        case CorporationTypes.VENDOR:
          return this.allowedAction(["V030102"]);
      }
    },
    isUpdateActions() {
      switch (this.entryCorporationRoot) {
        case CorporationTypes.CAFEREO:
          if (this.isCafereoList) {
            return this.allowedAction(["C060103"]);
          } else if (this.isMakerList) {
            return this.allowedAction(["C060203"]);
          } else if (this.isVendorList) {
            return this.allowedAction(["C060303"]);
          } else {
            return false;
          }
        case CorporationTypes.MAKER:
          return this.allowedAction(["M030103"]);
        case CorporationTypes.VENDOR:
          return this.allowedAction(["V030103"]);
      }
    },
    isDeleteActions() {
      switch (this.entryCorporationRoot) {
        case CorporationTypes.CAFEREO:
          if (this.isCafereoList) {
            return this.allowedAction(["C060104"]);
          } else if (this.isMakerList) {
            return this.allowedAction(["C060204"]);
          } else if (this.isVendorList) {
            return this.allowedAction(["C060304"]);
          } else {
            return false;
          }
        case CorporationTypes.MAKER:
          return this.allowedAction(["M030104"]);
        case CorporationTypes.VENDOR:
          return this.allowedAction(["V030104"]);
      }
    },
    isCsvActions() {
      switch (this.entryCorporationRoot) {
        case CorporationTypes.CAFEREO:
          if (this.isCafereoList) {
            return this.allowedAction(["C060105"]);
          } else if (this.isMakerList) {
            return this.allowedAction(["C060205"]);
          } else if (this.isVendorList) {
            return this.allowedAction(["C060305"]);
          } else {
            return false;
          }
        case CorporationTypes.MAKER:
          return this.allowedAction(["M030105"]);
        case CorporationTypes.VENDOR:
          return this.allowedAction(["V030105"]);
      }
    },
  },
};
</script>
