<template>
  <user-search></user-search>
</template>

<script>
import UserSearch from "./UserSearch.vue";
export default {
  name: "VendorUserSearch",
  components: {
    UserSearch,
  },
};
</script>
